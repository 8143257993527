class FileService {
    static async fetchGeoJSON(url) {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Erro ao carregar ${url}: ${response.statusText}`);
            }
            return await response.json();
        } catch (error) {
            console.error("Erro ao carregar o GeoJSON:", error);
            return null;
        }
    }
}

export default FileService;