// cache em memória, escopo global ou de módulo
const memoryCache = {};

/**
 * Faz fetch e armazena em cache usando uma chave.
 * @param {string} key - Chave única para identificar os dados no cache.
 * @param {string} url - Caminho ou endpoint da requisição.
 * @param {object} [options] - Opções para passar ao fetch (opcional).
 * @returns {Promise<any>} Dados retornados da requisição (ou do cache).
 */
export async function fetchWithMemoryCache(key, url, options = {}) {
  // 1. Verifica se existe no cache
  if (memoryCache[key]) {
    // Se existir, retorna o valor diretamente
    return memoryCache[key];
  }

  // 2. Se não existir, faz a requisição
  const response = await fetch(url, options);
  if (!response.ok) {
    throw new Error(`Requisição falhou: ${response.status}`);
  }

  const data = await response.json();

  // 3. Armazena o resultado no cache
  memoryCache[key] = data;

  return data;
}
