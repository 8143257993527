import style from './IBP.module.css'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Translator from '../I18n/Translator';
import { useNavigate } from 'react-router-dom';
import { UsuarioContext } from '../../common/context/UsuarioContext';
import { TemaContext } from '../../common/context/TemaContext';
import { LinguagemContext } from '../../common/context/LinguagemContext';
import { Button, Col, Container, Nav, Row, Table } from 'react-bootstrap';
import { Box, Slider, TextField, Autocomplete, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import L from 'leaflet';
import "./IBP.css";
import * as XLSX from 'xlsx';
import TabNav from '../TabNav';
import { ServerContext } from '../../common/context/ServerContext';

function IBP() {

  // Função auxiliar para obter o valor de um cookie.
  function getCookie(name) {
    const cookieName = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let cookie of cookieArray) {
      cookie = cookie.trim();
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length);
      }
    }
    return "";
  }

  // Guarda a posição inicial de scroll (pode ser transformada em state se necessário).
  const initialScrollPos = localStorage.getItem('posicaoScroll');

  // Contextos
  const { theme } = useContext(TemaContext);
  const { language } = useContext(LinguagemContext);
  const { logado } = useContext(UsuarioContext);
  const { servidor } = useContext(ServerContext);

  // States e refs gerais
  const [datas, setDatas] = useState([]);
  const [semanas, setSemanas] = useState([]);
  const [inicioSemanas, setInicioSemanas] = useState([]);
  const [fimSemanas, setFimSemanas] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const defaultZoom = 3.0;
  const zoomSnap_ = 0.25;
  const wheelPxPerZoomLevel_ = 120;
  const iniciou = useRef(false);
  const [dados_municipios_por_data, setDados_Municipios_Por_Data] = useState(null);

  // Variáveis para controle de estilo – convertidas para refs para atualizações diretas
  const weightNormalRef = useRef(theme === "dark" ? 0.6 : 0.6);
  const weightBrasil = 1.5;
  const weightSelecao = 3.5;
  const transparencia = useRef(0.5);
  const opacidadeMunicipio = 1.0;

  // Cores e estilos para o mapa
  const transparenciaDefaultMapa = useMemo(() => "rgba(255, 255, 255, 0)", []);
  const cinzaDefaultMapaDark = useMemo(() => "#272727", []);
  const cinzaDefaultMapaBorda = useMemo(() => "#C1c3c3", []);

  const corMapa1 = useMemo(() => "#000080", []);
  const corMapa1Borda = useMemo(() => "#000059", []);
  const corMapa1BordaDark = useMemo(() => "#4c4ccc", []);
  const corMapa2 = useMemo(() => "#0080ff", []);
  const corMapa2Borda = useMemo(() => "#0059b2", []);
  const corMapa2BordaDark = useMemo(() => "#4cccff", []);
  const corMapa3 = useMemo(() => "#b9fd82", []);
  const corMapa3Borda = useMemo(() => "#56b256", []);
  const corMapa3BordaDark = useMemo(() => "#c8ffc8", []);
  const corMapa4 = useMemo(() => "#ff9700", []);
  const corMapa4Borda = useMemo(() => "#b26a00", []);
  const corMapa4BordaDark = useMemo(() => "#ffe34c", []);
  const corMapa5 = useMemo(() => "#a80202", []);
  const corMapa5Borda = useMemo(() => "#450000", []);
  const corMapa5BordaDark = useMemo(() => "#e05a5a", []);

  // Refs para os layers e contadores do mapa
  const municipiosFaixa1 = useRef([]);
  const municipiosFaixa2 = useRef([]);
  const municipiosFaixa3 = useRef([]);
  const municipiosFaixa4 = useRef([]);
  const municipiosFaixa5 = useRef([]);
  const contadorFaixa1 = useRef(0);
  const contadorFaixa2 = useRef(0);
  const contadorFaixa3 = useRef(0);
  const contadorFaixa4 = useRef(0);
  const contadorFaixa5 = useRef(0);
  const atendIvasFaixa1 = useRef(0);
  const atendIvasFaixa2 = useRef(0);
  const atendIvasFaixa3 = useRef(0);
  const atendIvasFaixa4 = useRef(0);
  const atendIvasFaixa5 = useRef(0);
  const atendTotFaixa1 = useRef(0);
  const atendTotFaixa2 = useRef(0);
  const atendTotFaixa3 = useRef(0);
  const atendTotFaixa4 = useRef(0);
  const atendTotFaixa5 = useRef(0);
  const data_colormap = useRef([]);
  let color_scale = null;
  let escala = "ºC";
  const legend = useRef(null);

  // Refs para os shapefiles
  const shapeFileBrasil = useRef(null);
  const shapeFileMunicipios = useRef(null);

  // Variáveis da tabela
  const [campoOrdem, setCampoOrdem] = useState("classe ibp");
  const [decrescente, setDecrescente] = useState(false);

  // Gerenciamento do token de autenticação (usa cookie ou valor do contexto)
  const authToken = useMemo(() => {
    const cookieToken = getCookie("hashCache");
    return cookieToken ? cookieToken : logado;
  }, [logado]);

  useEffect(() => {
    if (!authToken) {
      navigate("/");
    }
  }, [authToken, navigate]);

  // Funções para exportar a tabela para Excel/CSV
  function exportToExcel(nomeTabela, nomeArquivo) {
    const htmlTable = document.getElementById(nomeTabela);
    if (!htmlTable) return;
    const tableCopy = htmlTable.cloneNode(true);
    cleanTableData(tableCopy);
    let dataAtual = new Date();
    let dataSelecionada = dataAtual.toLocaleString('pt-BR', { timeZone: 'UTC' })
      .replace(/\//g, "-")
      .substring(0, 10);
    let nomeArquivoLocal = dataSelecionada.replace(" ", "").replace(/-/g, "_").replace("00:00:00", "");
    const wb = XLSX.utils.table_to_book(tableCopy);
    XLSX.writeFile(wb, 'tabela_' + nomeArquivoLocal + '_' + nomeArquivo + '.xlsx');
  }

  function exportToCSV(nomeTabela, nomeArquivo) {
    const htmlTable = document.getElementById(nomeTabela);
    if (!htmlTable) return;
    const rows = htmlTable.querySelectorAll('tr');
    let csv = '';
    rows.forEach(row => {
      const cells = row.querySelectorAll('th,td');
      const rowData = Array.from(cells).map(cell => cell.textContent.replace('↑', ''));
      csv += rowData.join(',') + '\n';
    });
    let dataAtual = new Date();
    let dataSelecionada = dataAtual.toLocaleString('pt-BR', { timeZone: 'UTC' })
      .replace(/\//g, "-")
      .substring(0, 10);
    let nomeArquivoLocal = dataSelecionada.replace(" ", "").replace(/-/g, "_").replace("00:00:00", "");
    const blob = new Blob([csv], { type: 'text/csv;charset=iso-8859-1;' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'tabela_' + nomeArquivoLocal + '_' + nomeArquivo + '.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  function cleanTableData(table) {
    const cells = table.querySelectorAll('td');
    cells.forEach(cell => {
      cell.textContent = cell.textContent.replace(/↑/g, '');
    });
  }

  // Ajusta a comparação dos códigos IBGE convertendo ambos para número
  const getNomeCidadeWhereCodIbge = (cod_ibge_local) => {
    if (isNumeric(cod_ibge_local) && shapeFileMunicipios.current) {
      const keys = Object.keys(shapeFileMunicipios.current["_layers"]);
      for (let key of keys) {
        const properties = shapeFileMunicipios.current["_layers"][key].feature.properties;
        if (parseInt(properties["CD_MUN"]) === parseInt(cod_ibge_local)) {
          return properties["NM_MUN"];
        }
      }
    }
    return cod_ibge_local;
  };



  const isNumeric = (value) => /^\d+(?:\.\d+)?$/.test(value);

  // Dados dos estados e códigos
  var estados_labels = [
    { label: t("dashboard.todos") },
    { label: "Acre" },
    { label: "Alagoas" },
    { label: "Amapá" },
    { label: "Amazonas" },
    { label: "Bahia" },
    { label: "Ceará" },
    { label: "Distrito Federal" },
    { label: "Espírito Santo" },
    { label: "Goiás" },
    { label: "Maranhão" },
    { label: "Mato Grosso" },
    { label: "Mato Grosso do Sul" },
    { label: "Minas Gerais" },
    { label: "Pará" },
    { label: "Paraíba" },
    { label: "Paraná" },
    { label: "Pernambuco" },
    { label: "Piauí" },
    { label: "Rio de Janeiro" },
    { label: "Rio Grande do Norte" },
    { label: "Rio Grande do Sul" },
    { label: "Rondônia" },
    { label: "Roraima" },
    { label: "Santa Catarina" },
    { label: "São Paulo" },
    { label: "Sergipe" },
    { label: "Tocantins" },
  ];

  var cod_estados = [
    { "UF": "AC", "COD": 12, "NOME": "Acre" },
    { "UF": "AL", "COD": 27, "NOME": "Alagoas" },
    { "UF": "AP", "COD": 16, "NOME": "Amapá" },
    { "UF": "AM", "COD": 13, "NOME": "Amazonas" },
    { "UF": "BA", "COD": 29, "NOME": "Bahia" },
    { "UF": "CE", "COD": 23, "NOME": "Ceará" },
    { "UF": "DF", "COD": 53, "NOME": "Distrito Federal" },
    { "UF": "ES", "COD": 32, "NOME": "Espírito Santo" },
    { "UF": "GO", "COD": 52, "NOME": "Goiás" },
    { "UF": "MA", "COD": 21, "NOME": "Maranhão" },
    { "UF": "MT", "COD": 51, "NOME": "Mato Grosso" },
    { "UF": "MS", "COD": 50, "NOME": "Mato Grosso do Sul" },
    { "UF": "MG", "COD": 31, "NOME": "Minas Gerais" },
    { "UF": "PA", "COD": 15, "NOME": "Pará" },
    { "UF": "PB", "COD": 25, "NOME": "Paraíba" },
    { "UF": "PR", "COD": 41, "NOME": "Paraná" },
    { "UF": "PE", "COD": 26, "NOME": "Pernambuco" },
    { "UF": "PI", "COD": 22, "NOME": "Piauí" },
    { "UF": "RJ", "COD": 33, "NOME": "Rio de Janeiro" },
    { "UF": "RN", "COD": 24, "NOME": "Rio Grande do Norte" },
    { "UF": "RS", "COD": 43, "NOME": "Rio Grande do Sul" },
    { "UF": "RO", "COD": 11, "NOME": "Rondônia" },
    { "UF": "RR", "COD": 14, "NOME": "Roraima" },
    { "UF": "SC", "COD": 42, "NOME": "Santa Catarina" },
    { "UF": "SP", "COD": 35, "NOME": "São Paulo" },
    { "UF": "SE", "COD": 28, "NOME": "Sergipe" },
    { "UF": "TO", "COD": 17, "NOME": "Tocantins" },
  ];

  // Estados dos arquivos GeoJSON
  const [geojsonBrasil, setGeojsonBrasil] = useState(null);
  const [geojsonMunicipios, setGeojsonMunicipios] = useState(null);
  const [dadosCarregados, setDadosCarregados] = useState(false);

  async function fetchGeoJSON(url) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Erro ao carregar ${url}: ${response.statusText}`);
      }
      return await response.json();
    } catch (error) {
      console.error("Erro ao carregar o GeoJSON:", error);
      return null;
    }
  }
  
  async function loadGeoJSONData() {
    const brasilData = await fetchGeoJSON('Dados/shapefile/BR_UF_2022.json');
    const municipiosData = await fetchGeoJSON('Dados/shapefile/BR_Municipios_2022.json');
    if (brasilData && municipiosData) {
      setGeojsonBrasil(brasilData);
      setGeojsonMunicipios(municipiosData);
      setDadosCarregados(true);
    } else {
      console.error("Falha ao carregar um ou mais arquivos GeoJSON.");
    }
  }

  // Inicializa os layers do mapa assim que os dados são carregados.
  useEffect(() => {
    if (!dadosCarregados) return;
    if (!shapeFileBrasil.current) {
      shapeFileBrasil.current = L.geoJSON(geojsonBrasil, {
        interactive: false,
        style: (feature) => ({
          color: "black",
          fillColor: transparenciaDefaultMapa,
          stroke: true,
          weight: weightNormalRef.current,
          fillOpacity: transparencia.current
        }),
      });
    }
    if (!shapeFileMunicipios.current) {
      shapeFileMunicipios.current = L.geoJSON(geojsonMunicipios, {
        style: (feature) => ({
          color: "gray",
          fillColor: transparenciaDefaultMapa,
          stroke: true,
          weight: weightNormalRef.current,
          fillOpacity: opacidadeMunicipio
        }),
        onEachFeature: onEachFeatureEstado,
      });
    }
    if (map.current) {
      shapeFileBrasil.current.addTo(map.current);
      shapeFileMunicipios.current.addTo(map.current);
    }
  }, [dadosCarregados, geojsonBrasil, geojsonMunicipios]);

  // Atualiza a posição de scroll inicial.
  useEffect(() => {
    localStorage.setItem('posicaoScroll', JSON.stringify(0));
  }, []);

  // Ref para o mapa e camada de tiles.
  const layerTile = useRef(null);
  const map = useRef(null);

  function onEachFeatureEstado(feature, layer) {
    layer.on({
      mouseover: (e) => {
        if (feature.properties && feature.properties.NM_MUN) {
          layer.bindPopup(`${feature.properties.NM_MUN} - ${feature.properties.SIGLA_UF}`, {
            className: "popup-estilo"
          }).openPopup();
        }
      },
      mouseout: (e) => {
        layer.closePopup();
      },
      click: () => {
        if (cod_ibge === getCodIbgeShapefile(layer)) {
          map.current.fitBounds(layer.getBounds().pad(1));
          return;
        }
        setLoading(true);
        const siglaEstadoSelecionada = feature.properties.SIGLA_UF;
        const codEstadoSelecionado = getCodEstadoBySigla(siglaEstadoSelecionada);
        const nomeEstadoSelecionado = getNomeEstadoByCodIbge(codEstadoSelecionado);
        if (estadoSelecionado !== nomeEstadoSelecionado) {
          carregaMunicipiosPorEstado(siglaEstadoSelecionada);
          setEstadoSelecionado(nomeEstadoSelecionado);
        }
        setMunicipioSelecionado(feature.properties.NM_MUN);
        setCod_Ibge(getCodIbgeShapefile(layer));
        layer.setStyle({ weight: weightSelecao, color: "black", fillOpacity: opacidadeMunicipio });
        map.current.fitBounds(layer.getBounds().pad(1));
        setLoading(false);
      },
    });
  }

 /* function getCodIbgeShapefile(layer) {
    return layer.feature.properties["CD_MUN"];
  }

  const getCodIbgeShapefile = (shapefile, shape) => {
    let valor = shapefile["_layers"][shape].feature.properties["CD_MUN"];
    if (typeof valor === "number") {
      valor = valor.toString();
    }
    return valor;
  };*/

  // Retorna o nome do estado com base no código, procurando no array 'cod_estados'
const getNomeEstadoByCodIbge = (cod_ibge_local) => {
    // Certifica-se de que o código seja numérico para comparar corretamente
    const cod = Number(cod_ibge_local);
    for (let estado of cod_estados) {
      if (estado.COD === cod) {
        return estado.NOME;
      }
    }
    return cod_ibge_local;
  };
  
  // Verifica se a seleção corresponde a "All" ou "Todos"
  const isAllSelect = (variavel) => {
    return variavel === "All" || variavel === "Todos";
  };

  function getCodIbgeShapefile(arg1, arg2) {
    // Se o segundo argumento não for fornecido, arg1 é uma camada individual
    if (arg2 === undefined) {
      return arg1.feature.properties["CD_MUN"];
    }
    // Caso contrário, arg1 é o objeto shapefile e arg2 é a chave do layer
    let valor = arg1["_layers"][arg2].feature.properties["CD_MUN"];
    if (typeof valor === "number") {
      valor = valor.toString();
    }
    return valor;
  }
  

  const getCodEstadoBySigla = (sigla) => {
    for (let estado of cod_estados) {
      if (estado.UF === sigla) return estado.COD;
    }
  };

  const fixaCasasDecimais = (valor, casasDecimais) => Number(valor).toFixed(casasDecimais);

  const getDadosPorData = (dt, linguagem, pintaCirculo) => {
    if (dt) {
      fetch(`${servidor}/public/api/dadosdoenca/municipiosbydate/${dt.replace(" 00:00:00", "")}/1`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      })
        .then(response => response.json())
        .then(myJson => {
          setDados_Municipios_Por_Data(myJson);
          criarLegenda(0, 1, "ens", linguagem);
        });
    }
  };

  // Cria a legenda do mapa com base nos dados.
  function criarLegenda(min, max, variavel, linguagem) {
    if (!map.current) return;
    fetch(`${servidor}/public/api/classeibp/min_max_ibp`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    })
      .then(response => response.json())
      .then(myJson => {
        const valor1Min = myJson[0]["min_valor_ibp"];
        const valor1Max = myJson[0]["max_valor_ibp"];
        const valor2Min = myJson[1]["min_valor_ibp"];
        const valor2Max = myJson[1]["max_valor_ibp"];
        const valor3Min = myJson[2]["min_valor_ibp"];
        const valor3Max = myJson[2]["max_valor_ibp"];
        const valor4Min = myJson[3]["min_valor_ibp"];
        const valor4Max = myJson[3]["max_valor_ibp"];
        const valor5Min = myJson[4]["min_valor_ibp"];
        const valor5Max = myJson[4]["max_valor_ibp"];

        if (legend.current) {
          map.current.removeControl(legend.current);
          legend.current = null;
        }
        legend.current = L.control({ position: "bottomleft" });
        legend.current.onAdd = function (map) {
          const div = L.DomUtil.create("div", "legend_");
          div.innerHTML += `<center><h6 class="titulo-legend">${t("dashboard.ibp")}</h6></center>`;
          div.innerHTML += `<center><h6 class="titulo-legend">${t("dashboard.faixa")}      ${t("dashboard.sinais_faixa")}</h6></center>`;
          div.innerHTML += `<div class="legend-item"><div class="square" style="background-color: ${corMapa1}"></div>${fixaCasasDecimais(valor1Min, 3)} à ${fixaCasasDecimais(valor1Max, 3)}    ${contadorFaixa1.current}</div>`;
          div.innerHTML += `<div class="legend-item"><div class="square" style="background-color: ${corMapa2}"></div>${fixaCasasDecimais(valor2Min, 3)} à ${fixaCasasDecimais(valor2Max, 3)}    ${contadorFaixa2.current}</div>`;
          div.innerHTML += `<div class="legend-item"><div class="square" style="background-color: ${corMapa3}"></div>${fixaCasasDecimais(valor3Min, 3)} à ${fixaCasasDecimais(valor3Max, 3)}    ${contadorFaixa3.current}</div>`;
          div.innerHTML += `<div class="legend-item"><div class="square" style="background-color: ${corMapa4}"></div>${fixaCasasDecimais(valor4Min, 3)} à ${fixaCasasDecimais(valor4Max, 3)}    ${contadorFaixa4.current}</div>`;
          div.innerHTML += `<div class="legend-item"><div class="square" style="background-color: ${corMapa5}"></div>${fixaCasasDecimais(valor5Min, 3)} à ${fixaCasasDecimais(valor5Max, 3)}    ${contadorFaixa5.current}</div>`;
          return div;
        };
        legend.current.addTo(map.current);
      });
  }

  // FUNÇÃO ÚNICA: carrega os municípios de um estado (foi removida a duplicata)
  function carregaMunicipiosPorEstado(siglaEstado) {
    if (!loading) setLoading(true);
    if (siglaEstado !== '') {
      fetch(`${servidor}/public/api/municipio/municipiosbysiglaestado/${siglaEstado}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      })
        .then(response => response.json())
        .then(myJson => {
          const listaTemp = [];
          const cidades_labels_tmp = [{ cod_ibge: t("dashboard.todos"), label: t("dashboard.todos") }];
          myJson.forEach(item => {
            listaTemp.push({
              cod_ibge: item["COD_IBGE"],
              municipio: item["MUNICIPIO"],
              latitude: item["Y"],
              longitude: item["X"]
            });
            cidades_labels_tmp.push({ cod_ibge: item["COD_IBGE"], label: item["MUNICIPIO"] });
          });
          setCidades_Labels(cidades_labels_tmp);
          listaTemp.sort((a, b) => a.municipio.localeCompare(b.municipio));
          listaTemp.unshift({ cod_ibge: 0, municipio: t("dashboard.todos") });
          setListaMunicipios(listaTemp);
        });
    }
  }

  function getCidades(somenteNomes) {
    let nomes_municipios = [];
    if (somenteNomes) {
      nomes_municipios.unshift(t("dashboard.todos"));
      nomes_municipios.sort((a, b) => a.localeCompare(b));
    } else {
      nomes_municipios.unshift({ cod_ibge: 0, municipio: t("dashboard.todos") });
      nomes_municipios.sort((a, b) => a.municipio.localeCompare(b.municipio));
    }
    return nomes_municipios;
  }

  const getEstados = () => {
    let nomes_estados_tmp = [];
    fetch(`${servidor}/public/api/estado`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        data.forEach(item => nomes_estados_tmp.push(item));
      })
      .catch(error => console.error('Erro ao carregar o arquivo JSON:', error));
    nomes_estados_tmp.unshift({ UF: "All", ESTADO: t("dashboard.todos") });
    return nomes_estados_tmp;
  };

  const plotaMapa = (municipios, nomeFuncao) => {
    municipiosFaixa1.current = [];
    municipiosFaixa2.current = [];
    municipiosFaixa3.current = [];
    municipiosFaixa4.current = [];
    municipiosFaixa5.current = [];
    if (!shapeFileMunicipios.current) return;
    Object.keys(shapeFileMunicipios.current["_layers"]).forEach(shape => {
      const cod_ibge_tmp = getCodIbgeShapefile(shapeFileMunicipios.current, shape);
      const cod_ibge_selecionado = getCodIbgeCidadeWhereNome(municipioSelecionado);
      for (let x = 0; x < municipios.length; x++) {
        let weight = weightNormalRef.current;
        if (cod_ibge_tmp === municipios[x]["cod_mun"].toString()) {
          let cor = transparenciaDefaultMapa;
          let corContorno = theme === "dark" ? cinzaDefaultMapaDark : cinzaDefaultMapaBorda;
          if (municipios[x]["quintil_ibp"] === "No data" || municipios[x]["quintil_ibp"] === null) {
            cor = transparenciaDefaultMapa;
          } else if (municipios[x]["quintil_ibp"] === 1.0) {
            cor = corMapa1;
            corContorno = theme === "dark" ? corMapa1BordaDark : corMapa1Borda;
            municipiosFaixa1.current.push(municipios[x]["cod_mun"]);
          } else if (municipios[x]["quintil_ibp"] === 2.0) {
            cor = corMapa2;
            corContorno = theme === "dark" ? corMapa2BordaDark : corMapa2Borda;
            municipiosFaixa2.current.push(municipios[x]["cod_mun"]);
          } else if (municipios[x]["quintil_ibp"] === 3.0) {
            cor = corMapa3;
            corContorno = theme === "dark" ? corMapa3BordaDark : corMapa3Borda;
            municipiosFaixa3.current.push(municipios[x]["cod_mun"]);
          } else if (municipios[x]["quintil_ibp"] === 4.0) {
            cor = corMapa4;
            corContorno = theme === "dark" ? corMapa4BordaDark : corMapa4Borda;
            municipiosFaixa4.current.push(municipios[x]["cod_mun"]);
          } else if (municipios[x]["quintil_ibp"] === 5.0) {
            cor = corMapa5;
            corContorno = theme === "dark" ? corMapa5BordaDark : corMapa5Borda;
            municipiosFaixa5.current.push(municipios[x]["cod_mun"]);
          }
          if (cod_ibge_selecionado === shapeFileMunicipios.current["_layers"][shape].feature.properties["CD_MUN"]) {
            corContorno = "black";
            weight = weightSelecao;
          }
          shapeFileMunicipios.current["_layers"][shape].setStyle({
            weight: weight,
            color: corContorno,
            fillColor: cor,
            fillOpacity: opacidadeMunicipio
          });
          break;
        }
      }
    });
    criarLegenda(0, 1, "", language);
    setLoading(false);
  };

  const [loading, setLoading] = useState(true);
  const [cod_ibge, setCod_Ibge] = useState(t("dashboard.todos"));
  const [municipioSelecionado, setMunicipioSelecionado] = useState(t("dashboard.todos"));
  const [estadoSelecionado, setEstadoSelecionado] = useState(t("dashboard.todos"));
  const [listaMunicipios, setListaMunicipios] = useState(getCidades());
  const [cidades_labels, setCidades_Labels] = useState([{ cod_ibge: t("dashboard.todos"), label: t("dashboard.todos") }]);
  const [mapa_cor_value, setMapa_Cor_Value] = useState("jet");
  const [dados_Ivas_Pais, setDados_Ivas_Pais] = useState(null);
  const [dados_Municipio, setDados_Municipio] = useState(null);
  const [dados_Classes, setDados_Classes] = useState(null);
  const [link_zika, setLink_Zika] = useState("https://aesoparbov.outerlamce.com/");
  let link_gripe = "#";
  let dengue = true;

  var defaultProps = {
    options: estados_labels,
    getOptionLabel: (option) => option.label,
  };

  const defaultPropsMunicipio = {
    options: cidades_labels,
    getOptionLabel: (option) => option.label,
  };

  const handleChangeMunicipioNome = (nome_municipio) => {
    if (map.current) map.current.closePopup();
    if (nome_municipio === "All" || nome_municipio === "Todos") {
      Object.keys(shapeFileMunicipios.current["_layers"]).forEach(shape => {
        let corBordaAnt = shapeFileMunicipios.current["_layers"][shape].options.color;
        if (corBordaAnt === "#dddcdf") corBordaAnt = "black";
        shapeFileMunicipios.current["_layers"][shape].setStyle({ weight: weightNormalRef.current, color: corBordaAnt, fillOpacity: 1.0 });
      });
      setCod_Ibge(nome_municipio);
      setMunicipioSelecionado(getNomeCidadeWhereCodIbge(nome_municipio));
    } else {
      setCod_Ibge(nome_municipio);
      setMunicipioSelecionado(getNomeCidadeWhereCodIbge(nome_municipio));
      const keys = Object.keys(shapeFileMunicipios.current["_layers"]);
      for (let key of keys) {
        const elementLoop = shapeFileMunicipios.current["_layers"][key];
        if (nome_municipio === getCodIbgeShapefile(shapeFileMunicipios.current, key)) {
          map.current.fitBounds(elementLoop.getBounds().pad(1));
          break;
        }
      }
    }
  };

  const [listaColorMap, setListaColorMap] = useState([
    { "value": "gray", "nome": "Gray" },
    { "value": "jet", "nome": "Jet" },
    { "value": "rainbow", "nome": "Rainbow" },
    { "value": "gist_rainbow", "nome": "Gist Rainbow" },
    { "value": "hot", "nome": "Hot" },
    { "value": "brg", "nome": "brg" },
    { "value": "seismic", "nome": "seismic" },
    { "value": "cyan_light_to_blue_dark", "nome": "Cyan Light to Blue Dark" },
  ]);

  const valorNil = "nil";

  useEffect(() => {
    loadGeoJSONData();
    fetch(`${servidor}/public/api/dadosdoenca/pais/1`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    })
      .then(response => response.json())
      .then(data => setDados_Ivas_Pais(data))
      .catch(error => console.error('Erro ao carregar o arquivo JSON:', error));

    fetch(`${servidor}/public/api/classeibp/ibp`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    })
      .then(response => response.json())
      .then(myJson => {
        setDados_Municipio(myJson);
        plotaMapa(myJson, "useEffect 1");
        setLoading(false);
      });

    iniciou.current = true;
    localStorage.setItem('posicaoScroll', JSON.stringify(0));

    const dominio = window.location.hostname;
    if (dominio.includes("arbov")) {
      dengue = false;
    } else {
      dengue = true;
    }
    if (dominio.includes("aesopdev")) {
      setLink_Zika("https://aesoparbovdev.outerlamce.com/");
    } else if (dominio.includes("testeaesop.")) {
      setLink_Zika("https://testeaesoparbov.outerlamce.com/");
    } else {
      setLink_Zika("https://aesoparbov.outerlamce.com/");
    }
    if (dominio.includes("aesoparbovdev")) {
      link_gripe = "https://aesopdev.outerlamce.com/";
    } else if (dominio.includes("teste")) {
      link_gripe = "https://testeaesop.outerlamce.com/";
    } else {
      link_gripe = "https://aesop.outerlamce.com/";
    }
  }, [authToken, servidor, t]);

  useEffect(() => {
    if (dados_Ivas_Pais) {
      const datasTemp = [];
      const semanasTemp = [];
      const inicioSemanasTemp = [];
      const fimSemanasTemp = [];
      dados_Ivas_Pais.forEach(atendimento => {
        datasTemp.push(atendimento["data"]);
        semanasTemp.push((atendimento["semana"] < 10 ? "0" : "") + atendimento["semana"] + "-" + atendimento["data"].substring(0, 4));
        inicioSemanasTemp.push(atendimento["data_inicial_semana"]);
        fimSemanasTemp.push(atendimento["data_final_semana"]);
      });
      setDatas(datasTemp);
      setSemanas(semanasTemp);
      setInicioSemanas(inicioSemanasTemp);
      setFimSemanas(fimSemanasTemp);
      getDadosPorData(datasTemp[datasTemp.length - 1], language, true);
    }
  }, [dados_Ivas_Pais, language]);

  useEffect(() => {
    if (!map.current) {
      const url = theme === "dark"
        ? "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}"
        : "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}";
      const { innerWidth: width } = window;
      layerTile.current = L.tileLayer(url, {
        zoomControl: true,
        zoomSnap: zoomSnap_,
        zoomDelta: zoomSnap_,
        wheelPxPerZoomLevel: wheelPxPerZoomLevel_,
        defaultZoom: defaultZoom,
        maxZoom: 18,
        id: 'mapbox.streets'
      });
      map.current = L.map("map", {
        minZoom: 4,
        maxZoom: 10,
        layers: [layerTile.current]
      });
      const defaultCenter = [-15.0, -54.27390005057997];
      if (width < 1300)
        map.current.setView([-13.338793, -45.206666], defaultZoom);
      else
        map.current.setView(defaultCenter, defaultZoom);
      map.current.on('zoomend', event => {
        const currentZoom = event.target.getZoom();
        if (theme === "dark") {
          weightNormalRef.current = currentZoom <= 5 ? 0.5 : 1;
        }
      });
    } else {
      map.current.invalidateSize();
    }
  }, [theme]);

  useEffect(() => {
    criarLegenda(0, 1, "", language);
  }, [language]);

  useEffect(() => {
    if (!map.current) return;
    if (layerTile.current) map.current.removeLayer(layerTile.current);
    const url = theme === "dark"
      ? "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}"
      : "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}";
    const defaultCenter = [-15.0, -54.27390005057997];
    layerTile.current = L.tileLayer(url, {
      zoomControl: true,
      zoomSnap: zoomSnap_,
      zoomDelta: zoomSnap_,
      wheelPxPerZoomLevel: wheelPxPerZoomLevel_,
      defaultZoom: defaultZoom,
      maxZoom: 18,
      id: 'mapbox.streets'
    });
    layerTile.current.addTo(map.current);
    if (initialScrollPos) {
      setTimeout(() => window.scrollTo(0, initialScrollPos), 1);
    }
    if (dados_Municipio) plotaMapa(dados_Municipio, "Theme");
  }, [theme, dados_Municipio, initialScrollPos]);

  useEffect(() => {
    if (isAllSelect(estadoSelecionado)) {
      setEstadoSelecionado(t("dashboard.todos"));
    }
    estados_labels[0] = { label: t("dashboard.todos") };
    if (isAllSelect(municipioSelecionado)) {
      setListaMunicipios(getCidades());
      setMunicipioSelecionado(t("dashboard.todos"));
      setCidades_Labels([{ cod_ibge: t("dashboard.todos"), label: t("dashboard.todos") }]);
    }
    criarLegenda(0, 0, "", language);
  }, [language, estadoSelecionado, municipioSelecionado, t]);

  useEffect(() => {
    if (!map.current) return;
    let siglaEstado = '';
    let codigo_estado = 0;
    if (shapeFileBrasil.current) {
      Object.keys(shapeFileBrasil.current["_layers"]).forEach(shape => {
        if (isAllSelect(estadoSelecionado)) {
          shapeFileBrasil.current["_layers"][shape].setStyle({
            weight: weightNormalRef.current,
            color: transparenciaDefaultMapa,
            fillColor: transparenciaDefaultMapa,
            fillOpacity: 0.5
          });
        } else if (shapeFileBrasil.current["_layers"][shape].feature.properties["NM_UF"] === estadoSelecionado) {
          siglaEstado = shapeFileBrasil.current["_layers"][shape].feature.properties["SIGLA_UF"];
          codigo_estado = shapeFileBrasil.current["_layers"][shape].feature.properties["CD_UF"];
          shapeFileBrasil.current["_layers"][shape].setStyle({
            weight: 2,
            color: "black",
            fillColor: transparenciaDefaultMapa,
            fillOpacity: 0.5
          });
        } else {
          shapeFileBrasil.current["_layers"][shape].setStyle({
            weight: weightNormalRef.current,
            color: "black",
            fillColor: "black",
            fillOpacity: transparencia.current
          });
        }
      });
    }
    if (isAllSelect(estadoSelecionado) && !isAllSelect(municipioSelecionado)) {
      setMunicipioSelecionado(t("dashboard.todos"));
    } else if (isAllSelect(estadoSelecionado) && isAllSelect(municipioSelecionado)) {
      const { innerWidth: width } = window;
      const defaultCenter = [-15.0, -54.27390005057997];
      if (width < 1300)
        map.current.setView([-13.338793, -45.206666], defaultZoom);
      else
        map.current.setView(defaultCenter, defaultZoom);
    }
    let codMunSelecionado = getCodIbgeCidadeWhereNome(municipioSelecionado);
    if (shapeFileMunicipios.current) {
      Object.keys(shapeFileMunicipios.current["_layers"]).forEach(shape => {
        let fillOpacity = transparencia.current;
        let corBordaAnt = shapeFileMunicipios.current["_layers"][shape].options.fillColor;
        let weightLocal = weightNormalRef.current;
        const codMunicipioShapeAtual = shapeFileMunicipios.current["_layers"][shape].feature.properties["CD_MUN"];
        if (!isAllSelect(municipioSelecionado) && codMunSelecionado === codMunicipioShapeAtual) {
          corBordaAnt = "black";
          weightLocal = weightSelecao;
          fillOpacity = opacidadeMunicipio;
        } else if (corBordaAnt === corMapa1) {
          corBordaAnt = theme === "dark" ? corMapa1BordaDark : corMapa1Borda;
        } else if (corBordaAnt === corMapa2) {
          corBordaAnt = theme === "dark" ? corMapa2BordaDark : corMapa2Borda;
        } else if (corBordaAnt === corMapa3) {
          corBordaAnt = theme === "dark" ? corMapa3BordaDark : corMapa3Borda;
        } else if (corBordaAnt === corMapa4) {
          corBordaAnt = theme === "dark" ? corMapa4BordaDark : corMapa4Borda;
        } else if (corBordaAnt === corMapa5) {
          corBordaAnt = theme === "dark" ? corMapa5BordaDark : corMapa5Borda;
        } else {
          corBordaAnt = theme === "dark" ? cinzaDefaultMapaDark : cinzaDefaultMapaBorda;
        }
        shapeFileMunicipios.current["_layers"][shape].setStyle({
          weight: weightLocal,
          color: corBordaAnt,
          fillOpacity: opacidadeMunicipio
        });
      });
    }
    if (!isAllSelect(municipioSelecionado)) return;
    if (shapeFileBrasil.current) {
      setLoading(true);
      if (codigo_estado === 0) {
        setListaMunicipios(getCidades());
        setCidades_Labels([{ cod_ibge: t("dashboard.todos"), label: t("dashboard.todos") }]);
      }
      carregaDadosEstado(codigo_estado, siglaEstado);
    }
  }, [estadoSelecionado, municipioSelecionado]);

  const getCodIbgeCidadeWhereNome = (cod_ibge_local) => {
    if ((!isNumeric(cod_ibge_local) || cod_ibge_local.length === 6) && shapeFileMunicipios.current) {
      const keys = Object.keys(shapeFileMunicipios.current["_layers"]);
      for (let key of keys) {
        const element = shapeFileMunicipios.current["_layers"][key];
        const cod_temp = element.feature.properties["CD_MUN"].substring(0, 6);
        if (element.feature.properties["NM_MUN"] === cod_ibge_local || cod_temp === cod_ibge_local) {
          return element.feature.properties["CD_MUN"];
        }
      }
    }
    return cod_ibge_local;
  };

  const [indice, setIndice] = useState("mob");
  const getNomeCampoIndice = (indice) => "";
  const preparaInt = (valor) => valor.toString().replace(".", "").replace(",", "");
  const trataNaNeNull = (dado) => (dado === null || dado === "" || dado === "NaN" ? valorNil : dado);

  const tabelaOrdenada = () => {
    if (!dados_Classes || semanas.length === 0) return [];
    const semanaSelecionada = semanas[semanas.length - 1].substring(0, 2);
    const anoSelecionado = semanas[semanas.length - 1].substring(3, 7);
    let tMakers = dados_Classes.filter(item => String(item.epiyear) === anoSelecionado && String(item.epiweek) === semanaSelecionada);
    if (tMakers.length === 0) return [];
    tMakers.forEach(item => {
      if (item.dqi === null) {
        item.dqi = "Não Apto";
      }
    });
    const campoOrdemLocal = campoOrdem;
    if (["num_sinais_arbov", "num_sinais_otc", "num_sinais_ivas"].includes(campoOrdemLocal)) {
      tMakers.sort((a, b) => decrescente ? b[campoOrdemLocal] - a[campoOrdemLocal] : a[campoOrdemLocal] - b[campoOrdemLocal]);
    } else if (["classe ibp", "estado", "dqi"].includes(campoOrdemLocal)) {
      tMakers.sort((a, b) => decrescente ? b[campoOrdemLocal].localeCompare(a[campoOrdemLocal]) : a[campoOrdemLocal].localeCompare(b[campoOrdemLocal]));
    } else if (campoOrdemLocal === "classe ibp") {
      tMakers.sort((a, b) => decrescente ? (a.num_classe_ibp > b.num_classe_ibp ? -1 : 1) : (a.num_classe_ibp > b.num_classe_ibp ? 1 : -1));
    } else {
      tMakers.sort((a, b) => decrescente ? (a[campoOrdemLocal] > b[campoOrdemLocal] ? -1 : 1) : (a[campoOrdemLocal] > b[campoOrdemLocal] ? 1 : -1));
    }
    if (initialScrollPos) setTimeout(() => window.scrollTo(0, initialScrollPos), 1);
    return tMakers;
  };

  const carregaDadosEstado = (codigo_estado, siglaEstado) => {
    if (codigo_estado !== 0) {
      const keys = Object.keys(shapeFileBrasil.current["_layers"]);
      for (let key of keys) {
        const element = shapeFileBrasil.current["_layers"][key];
        if (element && element.feature.properties["NM_UF"] === estadoSelecionado) {
          map.current.fitBounds(element.getBounds());
          break;
        }
      }
      carregaMunicipiosPorEstado(siglaEstado);
    }
    setLoading(false);
  };

  const handleChangeMunicipioTabela = (event) => {
    setMunicipioSelecionado(event.target.value);
  };

  const handleChangeEstadoTabela = (event) => {
    setEstadoSelecionado(event.target.value);
  };

  useEffect(() => {
    fetch(`${servidor}/public/api/classeibp/ivas`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    })
      .then(response => response.json())
      .then(myJson => {
        setDados_Classes(myJson);
        if (semanas.length !== 0) {
          const semanaSelecionada = semanas[semanas.length - 1].substring(0, 2);
          const anoSelecionado = semanas[semanas.length - 1].substring(3, 7);
          myJson.forEach(item => {
            if (String(item.epiyear) === anoSelecionado && String(item.epiweek) === semanaSelecionada) {
              switch (item.num_classe_ibp) {
                case 1:
                  contadorFaixa1.current = item.num_sinais_ivas;
                  break;
                case 2:
                  contadorFaixa2.current = item.num_sinais_ivas;
                  break;
                case 3:
                  contadorFaixa3.current = item.num_sinais_ivas;
                  break;
                case 4:
                  contadorFaixa4.current = item.num_sinais_ivas;
                  break;
                case 5:
                  contadorFaixa5.current = item.num_sinais_ivas;
                  break;
              }
            }
          });
        }
      });
  }, [semanas, authToken, servidor]);

  return (
    <Container className="container_Mapa">
      {loading && (
        <div className={style.loading}>
          <div className={style.lds_ring}>
            <div></div><div></div><div></div><div></div>
          </div>
        </div>
      )}
      <Row>
        <TabNav theme={theme} language={language} currentPage={"IBP"} link_gripe="" link_zika={link_zika} />
        <Row>
          <div className={style.div_top}>
            <br />
            <div className='titulo_semana_ep' style={{ marginBottom: "10px" }}>
              <Translator path="dashboard.semana_ep" />: {semanas[semanas.length - 1]}
            </div>
            <div className={style.container_seleciona_municipio_e_cor}>
              <div className={style.seleciona_municipio}>
                <Autocomplete
                  {...defaultProps}
                  disableClearable
                  id="autocomplete_estado"
                  className='change_color_text'
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  value={{ label: estadoSelecionado }}
                  onChange={(event, newValue) => {
                    setLoading(true);
                    setEstadoSelecionado(newValue.label);
                    setCod_Ibge(t("dashboard.todos"));
                    setMunicipioSelecionado(t("dashboard.todos"));
                  }}
                  renderInput={(params) => (
                    <TextField className='autocomplete_estado_textfield' {...params} label={t("dashboard.estado")} size="small" />
                  )}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Autocomplete
                  {...defaultPropsMunicipio}
                  disableClearable
                  id="autocomplete_municipio"
                  className='change_color_text'
                  isOptionEqualToValue={(option, value) => option.cod_ibge === value.cod_ibge}
                  value={{ cod_ibge: cod_ibge, label: municipioSelecionado }}
                  onChange={(event, newValue) => {
                    handleChangeMunicipioNome(newValue.cod_ibge);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label={t("dashboard.cidade")} size="small" />
                  )}
                />
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <Col md={12}>
            <div className={style.mapa}>
              <div id="map" className={style.map}></div>
            </div>
          </Col>
        </Row>
        <div className='espacamento'></div>
        <div className={style.table}>
          <div className={style.header_tabela}>
            <div className={style.titulo_tabela}>
              <h5><center>IBP</center></h5>
            </div>
            <div className={style.export_buttons}>
              <div className='divExportPlanilhaCSV'>
                <img className="exportPlanilhaCSV" src='/imagens/csv_logo.gif' onClick={() => exportToCSV("dtBasicExampleRank", "rank")} />
                <div className="balaoDeTextoCsv noHover">Download CSV</div>
              </div>
              <div className={style.div_export_planilha_excel}>
                <img className={style.export_planilha} src='/imagens/excel_logo.gif' onClick={() => exportToExcel("dtBasicExampleRank", "rank")} />
                <div className={style.balao_de_texto_excel}>Download XLSX</div>
              </div>
            </div>
          </div>
          <Table id="dtBasicExampleRank" striped bordered hover variant={theme === "dark" ? "dark" : ""}>
            <thead>
              <tr>
                <th>
                  <a href='#' style={{ color: campoOrdem === "classe ibp" ? "cadetblue" : "" }}
                    onClick={() => {
                      campoOrdem === "classe ibp" ? setDecrescente(!decrescente) : setDecrescente(false);
                      setCampoOrdem("classe ibp");
                    }}>
                    <img className={theme === "dark" ? '' : style.invert} src="/imagens/icones/setas-ordenadas.png" /> <Translator path={"dashboard.table.classe_ibp"} />
                  </a>
                </th>
                <th>
                  <a href='#' style={{ color: campoOrdem === "epiyear" ? "cadetblue" : "" }}
                    onClick={() => {
                      campoOrdem === "epiyear" ? setDecrescente(!decrescente) : setDecrescente(false);
                      setCampoOrdem("epiyear");
                    }}>
                    <img className={theme === "dark" ? '' : style.invert} src="/imagens/icones/setas-ordenadas.png" /> <Translator path={"dashboard.ano"} />
                  </a>
                </th>
                <th>
                  <a href='#' style={{ color: campoOrdem === "epiweek" ? "cadetblue" : "" }}
                    onClick={() => {
                      campoOrdem === "epiweek" ? setDecrescente(!decrescente) : setDecrescente(false);
                      setCampoOrdem("epiweek");
                    }}>
                    <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png' /> <Translator path={"dashboard.semana_ep"} />
                  </a>
                </th>
                <th>
                  <a href='#' style={{ color: campoOrdem === "num_sinais_ivas" ? "cadetblue" : "" }}
                    onClick={() => {
                      campoOrdem === "num_sinais_ivas" ? setDecrescente(!decrescente) : setDecrescente(false);
                      setCampoOrdem("num_sinais_ivas");
                    }}>
                    <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png' /> <Translator path={"dashboard.num_sinais_ensamble"} />
                  </a>
                </th>
                <th>
                  <a href='#' style={{ color: campoOrdem === "atend_ivas" ? "cadetblue" : "" }}
                    onClick={() => {
                      campoOrdem === "atend_ivas" ? setDecrescente(!decrescente) : setDecrescente(false);
                      setCampoOrdem("atend_ivas");
                    }}>
                    <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png' /> <Translator path={"dashboard.table.col7"} />
                  </a>
                </th>
                <th>
                  <a href='#' style={{ color: campoOrdem === "atend_totais" ? "cadetblue" : "" }}
                    onClick={() => {
                      campoOrdem === "atend_totais" ? setDecrescente(!decrescente) : setDecrescente(false);
                      setCampoOrdem("atend_totais");
                    }}>
                    <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png' /> <Translator path={"dashboard.table.col8"} />
                  </a>
                </th>
                <th>
                  <a href='#' style={{ color: campoOrdem === "prop_ivas" ? "cadetblue" : "" }}
                    onClick={() => {
                      campoOrdem === "prop_ivas" ? setDecrescente(!decrescente) : setDecrescente(false);
                      setCampoOrdem("prop_ivas");
                    }}>
                    <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png' /> <Translator path={"dashboard.table.col6"} /> (%)
                  </a>
                </th>
                <th>
                  <a href='#' style={{ color: campoOrdem === "media_movel_ivas" ? "cadetblue" : "" }}
                    onClick={() => {
                      campoOrdem === "media_movel_ivas" ? setDecrescente(!decrescente) : setDecrescente(false);
                      setCampoOrdem("media_movel_ivas");
                    }}>
                    <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png' /> <Translator path={"dashboard.table.media_movel_proporcao"} /> (%)
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              {tabelaOrdenada().map((value, index) => (
                <tr key={index}>
                  <td className={style.linha_tabela}>
                    <a href='#' onClick={() => handleChangeEstadoTabela({ target: { value: value["classe ibp"] } })}>
                      {value["classe ibp"]}
                    </a>
                  </td>
                  <td className={style.linha_tabela}>
                    <a href='#' onClick={() => handleChangeMunicipioTabela({ target: { value: value["epiyear"] } })}>
                      {value["epiyear"]}
                    </a>
                  </td>
                  <td className={style.linha_tabela}>
                    <a href='#' onClick={() => handleChangeMunicipioTabela({ target: { value: value["epiweek"] } })}>
                      {value["epiweek"]}
                    </a>
                  </td>
                  <td className={`${style.linha_tabela} ${style.numero_tabela}`}>
                    {preparaInt(trataNaNeNull(value["num_sinais_ivas"]))}
                  </td>
                  <td className={`${style.linha_tabela} ${style.numero_tabela}`}>
                    {preparaInt(trataNaNeNull(value["atend_ivas"]))}
                  </td>
                  <td className={`${style.linha_tabela} ${style.numero_tabela}`}>
                    {preparaInt(trataNaNeNull(value["atend_totais"]))}
                  </td>
                  <td className={`${style.linha_tabela} ${style.numero_tabela}`}>
                    {fixaCasasDecimais(trataNaNeNull(value["prop_ivas"]), 2)}
                  </td>
                  <td className={`${style.linha_tabela} ${style.numero_tabela}`}>
                    {fixaCasasDecimais(trataNaNeNull(value["media_movel_ivas"]), 2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Row>
    </Container>
  );
}

export default IBP;
