import React, { useContext, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { UsuarioContext } from "../../common/context/UsuarioContext";
import CryptoJS from 'crypto-js';
import { ServerContext } from "../../common/context/ServerContext";
import { fetchWithMemoryCache } from "../../service/MemoryCache";

function setCookie(name, value, hours) {
  // Calcula a data de expiração do cookie
  const date = new Date();
  date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();

  // Define o cookie com o nome, valor e data de expiração
  //document.cookie = name + "=" + value + ";" + expires + ";domain=.outerlamce.com;;path=/";
  document.cookie = name + "=" + value + ";" + expires + ";domain=outerlamce.com;;path=/";
}

function getCookie(name) {
  // Cria uma expressão regular para encontrar o cookie pelo nome
  const cookieName = name + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  // Percorre todos os cookies para encontrar o desejado
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return "";
}

const Login = (props/*{linguagem, logado, loginToParent}*/) => { 

    //const servidor = "https://aesopdev.outerlamce.com/api/index.php";

    const navigate = useNavigate();

    const {logado, setLogado} = useContext(UsuarioContext);
    const {servidor} = useContext(ServerContext);

    const LOGIN_URL = servidor + "/public/api/login";
    const LAST_DATE_URL = servidor + "/public/api/tempo/data/last";


    const [carregou, setCarregou] = React.useState(false);

    let secretKey = "aesopdash";
    let login = "login@aesop.com";
    let senha = "123456";

    useEffect(() => {
        if(logado){
          if(getCookie('hashCache')){
            //let hashCache = decryptString(localStorage.getItem('hashCache'), secretKey);
            let hashCache = getCookie('hashCache');
    
            fetch(servidor + "/public/api/pais", {
                method: 'GET',
                headers: {
                  'Accept': 'application/json',
                  'Authorization': hashCache,
              }}
            ).then((response) => {
              if (!response.ok) {
                  if(response.status === 401){
                    throw new Error(`Token Expirado: ${response.status}`);
                  }
                  throw new Error(`Erro na requisição: ${response.status}`);
              } else {
                setLogado(hashCache);
                localStorage.setItem("logado",logado); 
                navigate("/mapa");
              }

            })
    
            
          } 
        }
    }, [logado]);

    // Função para criptografar uma string
    const encryptString = (inputString, secretKey) => {
        return CryptoJS.AES.encrypt(inputString, secretKey).toString();
    };
  
    // Função para descriptografar uma string
    const decryptString = (encryptedString, secretKey) => {
        const bytes = CryptoJS.AES.decrypt(encryptedString, secretKey);
        return bytes.toString(CryptoJS.enc.Utf8);
    };

    const fetchData = async () => {
      const response = await fetch(LAST_DATE_URL, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        },
      });
      const data = await response.json();
      let dataCache = new Date (localStorage.getItem('dataCacheFiles'));
      let dataArquivo = new Date(data.data_final_semana);

      //comparar data do arquivo com a data do cache
      let dataComparacao  = new Date(localStorage.getItem('dataCacheFiles'));
      if(dataCache)
          dataComparacao.setHours(dataCache.getHours() + 1);
      
     if(localStorage.getItem('dataCacheFiles') && (dataComparacao < dataArquivo)){
        localStorage.setItem('dataCacheFiles', dataArquivo.toString());
        console.log('Recarregando cache');
        recarregaCache();
        console.log('Recarregando cache');
      } else {
        localStorage.setItem('dataCacheFiles', dataArquivo.toString());
      }
    };

    useEffect(() => {
      setCarregou(false);

      fetchData();

      //Refresh cache - 2 dias
      let dataAtual = new Date();
      if(localStorage.getItem('dataCache')){
          let dataCache = new Date (localStorage.getItem('dataCache'));
          let dataComparacao  = new Date(localStorage.getItem('dataCache'));
          if(dataCache)
              dataComparacao.setDate(dataCache.getDate() + 2);

          if(dataCache && dataComparacao < dataAtual){
              recarregaCache();
              dataCache = dataAtual;
              localStorage.setItem('dataCache', dataCache.toString());
          }
      } else {
          localStorage.setItem('dataCache', dataAtual.toString());
      }
      
      if(getCookie('hashCache')){
        //let hashCache = decryptString(localStorage.getItem('hashCache'), secretKey);
        let hashCache = getCookie('hashCache');

        fetch(servidor + "/public/api/pais", {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Authorization': hashCache,
          }}
        ).then((response) => {
          if (!response.ok) {
              if(response.status === 401){
                throw new Error(`Token Expirado: ${response.status}`);
              }
              throw new Error(`Erro na requisição: ${response.status}`);
          } else {
              setLogado(hashCache);
          }
          // Converte o corpo da resposta em JSON
          //return response.json();
        })

        /*let dataHashCache = new Date(parts[2]);

        const diffInMilliseconds = dataAtual - dataHashCache;
        const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
        //console.log(hashCache, diffInMinutes);

        let remotandoUsuario = {
          "email": parts[0],
          "idestado": parts[1],
          "idusuario": parts[3],
          "nivel": parts[4],
          "nome": parts[5],
          "senha": parts[6]
        };

        if(diffInMinutes < 30){
          //console.log('Cache valido');
          //fazerLogin(parts[0], parts[1]);    
          setLogado(remotandoUsuario);
          //console.log(hashCache);   
          return;
        }*/
      } 

      setCarregou(true);

      const fetchGeoJSON = async (url) => {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Erro ao carregar ${url}: ${response.statusText}`);
            }
            return await response.json();
        } catch (error) {
            console.error("Erro ao carregar o GeoJSON:", error);
            return null;
        }
      };

      
      //const brasilData = fetchGeoJSON('Dados/shapefile/BR_UF_2022.json');
      //const municipiosData = fetchGeoJSON('Dados/shapefile/BR_Municipios_2022.json');
      
      fetchWithMemoryCache('brasilTopoJSON', 'Dados/topojson/BR_UF_2022.json');
      fetchWithMemoryCache('municipiosTopoJSON', 'Dados/topojson/BR_Municipios_2022.json');
      if(logado){
        let options = {headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${logado}`
        }};
  
        fetchWithMemoryCache('estadoAPI', servidor + '/public/api/estado', options);
        fetchWithMemoryCache('dadosDoencaPaisAPI', servidor + '/public/api/dadosdoenca/pais/1', options);
      }
    }, []);

    const recarregaCache = () => {
      window.location.reload(true);
    };

    async function  fazerLogin(login, senha) {
      try {
        // 1) Fazendo a requisição de LOGIN
        const response = await fetch(LOGIN_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json", 
            "Accept-Encoding": "application/json",       
          },
          body: JSON.stringify({
            email: login,
            password: senha,
          }),
        });
  
        // Verifica se a resposta não foi bem sucedida (HTTP >= 400)
        if (!response.ok) {
          throw new Error("Falha ao fazer login. Verifique suas credenciais.");
        }
  
        const data = await response.json();
        //console.log("Resposta do login:", data);
  
        if (!data.access_token) {
          throw new Error("Token não retornado. Verifique o endpoint ou as credenciais.");
        }
  
        // 2) Armazena o token Bearer
        const token = data.token_type + " " + data.access_token;
        //setBearerToken(token);
  
        if(data.access_token){
          //setCookie('hashCacheCievs', encryptString(e.target.usuario.value + ";" + e.target.password.value + ";" + new Date().toString(), secretKey), 0.5);
          //setCookie('hashCache', encryptString(login + ";" + senha + ";" + new Date().toString(), secretKey), 0.5);
          setCookie('hashCache', token, 0.5);
        
          setLogado(token);
        } else {
          if(carregou)
            alert("Email ou Senha inválido.");
          //return null;
        }
  
      } catch (err) {
        //setError(err.message);
        console.error("Ocorreu um erro:", err);
      } finally {
       // setLoading(false);
      }



      /*fetch(servidor, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",       
        },
        body: JSON.stringify({
          email: login,
          password: senha
        })
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          // Handle the response data here
          if(data["sucesso"]){
            if(decryptString(senha, secretKey) === decryptString(data["dados"][0].senha, secretKey)){
              //setCookie('hashCacheCievs', encryptString(e.target.usuario.value + ";" + e.target.password.value + ";" + new Date().toString(), secretKey), 0.5);
              //setCookie('hashCache', encryptString(login + ";" + senha + ";" + new Date().toString(), secretKey), 0.5);
              setCookie('hashCache', encryptString(login + ";" + senha + ";" + new Date().toString() + ";" + data["dados"][0]["idestado"] + ";" + data["dados"][0]["idusuario"]+ ";" + data["dados"][0]["nivel"]+ ";" + data["dados"][0]["nome"], secretKey), 0.5);
            
              setLogado(data["dados"][0]);
            } else {
              if(carregou)
                alert("Email ou Senha inválido.");
              //return null;
            }
          } else {
            if(carregou)
              alert("Email ou Senha inválido");
            //return null;
          }
        })
        .catch(error => {
          // Handle any errors here
          console.error(error);
          //return null;
        });*/
    }

    function handleSubmit(e) {
        e.preventDefault();

        if (!e.target.email.value) {
          alert("Preencha o Email");
        } else if (!e.target.email.value) {
          alert("Email inválido");
        } else if (!e.target.password.value) {
          alert("Preencha a senha");
        } else {
          fazerLogin(e.target.email.value, e.target.password.value/*encryptString(e.target.password.value, secretKey)*/);
          console.log(encryptString(e.target.password.value, secretKey));
          e.target.email.value = "";
          e.target.password.value = "";          
        } 
      };
      

    function exibePagina() {
        return (
            <center>
            {carregou ?  
              <div className="Login">
              <h3>Login</h3>
              <form className="form" onSubmit={handleSubmit}>
                  <div className="input-group">
                  <label htmlFor="email">Email</label>
                  <input type="email" name="email" placeholder="nome@email.com.br" />
                  </div>
                  <div className="input-group">
                  <label htmlFor="password">Senha</label>
                  <input type="password" name="password" />
                  </div>
                  <button className="primary">ENTRAR</button>
              </form>
              </div>
              : 
              <div></div>
            }
            </center>
        );
    }

  
    return (exibePagina());
  
}

export default Login;
